<template>
    <div v-if="launch" class="launch-container">
        <div class="images">
            <template v-for="(image, index) in images">
                <img :src="image" :key="index" />
            </template>
        </div>
        <div class="details">
            <div @click="back" class="back">
                <font-awesome-icon icon="chevron-left" /> Back
            </div>
            <div class="launch-details">
                <h1>{{ launch.name }}</h1>
                <p>{{ launch.details }}</p>
            </div>
            <div class="rocket-details">
                <h3>Rocket Details</h3>
                <p>
                    Name: {{ rocketDetails.name }}<br />
                    Type: {{ rocketDetails.type }}<br />
                    Cost Per Launch: {{ rocketDetails.cost_per_launch }}<br />
                    Success Rate: {{ rocketDetails.success_rate_pct }}%<br />
                    First Flight: {{ rocketDetails.first_flight }}<br />
                </p>
            </div>
            <div class="launchpad-details">
                <h3>Launchpad Details</h3>
                <p>
                    Name: {{ launchpad.name }}<br />
                    Full Name: {{ launchpad.full_name }}<br />
                    Locality: {{ launchpad.locality }}<br />
                    Region: {{ launchpad.region }}<br />
                    Launch Attempts: {{ launchpad.launch_attempts }}<br />
                    Launch Successes: {{ launchpad.launch_successes }}<br />
                </p>
                <l-map style="height: 300px" :zoom="13" :center="[launchpad.latitude, launchpad.longitude]">
                    <l-tile-layer :url="url"></l-tile-layer>
                    <l-marker :lat-lng="[launchpad.latitude, launchpad.longitude]"></l-marker>
                </l-map>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            launch: null
        }
    },
    async mounted () {
        const launchId = this.$route?.params?.launchId
        this.launch = this.$store.getters['spacex/launch'](launchId)
        await this.$store.dispatch('spacex/fetchLaunchpads')

        if (!this.launch) {
            await this.$store.dispatch('spacex/fetchRockets')
            await this.$store.dispatch('spacex/fetchUpcomingLaunches')
            this.launch = this.$store.getters['spacex/launch'](launchId)
        }

        console.log(this.launch)
    },
    computed: {
        images () {
            let images = this.launch?.links?.flickr?.original ?? []

            if (images.length === 0) {
                console.log(this.launch)
                images = this.rocketDetails?.flickr_images ?? []
            }

            return images
        },
        rocketDetails () {
            const { rocket } = this.launch
            return this.$store.getters['spacex/rocket'](rocket)
        },
        launchpad () {
            const { launchpad: launchpadId } = this.launch
            return this.$store.getters['spacex/launchpad'](launchpadId)
        }
    },
    methods: {
        back () {
            this.$router.push({
                name: 'spacex'
            })
        }
    }
}
</script>
