<template>
    <div class="spacex-container">
        <h1>SpaceX Upcoming Launches</h1>
        <template v-if="upcomingLaunches.length > 0 && markers.length > 0">
            <div class="launchpad-map-container">
                <l-map style="height: 300px" :bounds="bounds">
                    <l-tile-layer :url="url"></l-tile-layer>
                    <template v-for="(marker, index) in markers">
                        <l-marker :key="`marker-${index}`" :name="marker.name" :lat-lng="marker.latlng">
                            <l-tooltip :options="{ permanent: false, interactive: true }">
                                {{ marker.name }}
                            </l-tooltip>
                            <l-popup>
                                <p>
                                    <template v-for="(launch, index) in launchesByPad(marker)">
                                        <span :key="`launch-name-${index}`">{{ launch.name }} ({{launchDateTime(launch)}}) <br/></span>
                                    </template>
                                </p>
                            </l-popup>
                        </l-marker>
                    </template>
                </l-map>
            </div>
            <hr />
            <div class="upcoming-launches">
                <div v-for="(launch, index) in upcomingLaunches" :key="index" class="card">
                    <img v-if="image(launch)" :src="image(launch)" alt="Avatar" style="width:100%">
                    <div class="container">
                        <h4 style="margin-top: 0;margin-bottom: 0.5rem;"><b>{{ launch.name }}</b></h4>
                        <p>
                            Launch: {{ launchDateTime(launch) }}<br />
                            Rocket: {{ rocketName(launch) }}<br />
                            Success: {{ rocketSuccessRate(launch) }}<br />
                        </p>
                        <div @click="viewLaunch(launch)" class="view">
                            View
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-esle>
            <p>No upcoming launches</p>
        </template>
    </div>
</template>

<script>

import { DateTime } from 'luxon'

export default {
    data () {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        }
    },
    async mounted () {
        await this.$store.dispatch('spacex/fetchRockets')
        await this.$store.dispatch('spacex/fetchUpcomingLaunches')
        await this.$store.dispatch('spacex/fetchLaunchpads')
    },
    computed: {
        upcomingLaunches () {
            const t = this.$store.getters['spacex/upcomingLaunches']
            return t.sort((a, b) => a.date_unix - b.date_unix)
        },
        markers () {
            const launchs = JSON.parse(JSON.stringify(this.upcomingLaunches))
            const launchpadIds = launchs.map(({ launchpad }) => launchpad)
            const launchpads = this.$store.getters['spacex/launchpads'].filter(({ id }) => launchpadIds.includes(id))

            return launchpads.map(({ id, latitude, longitude, name }) => {
                return {
                    id: id,
                    name: name,
                    latlng: [latitude, longitude]
                }
            })
        },
        bounds () {
            return this.markers.length === 0 ? [] : this.markers.map(({ latlng }) => latlng)
        }
    },
    methods: {
        image (launch) {
            let images = launch?.links?.flickr?.original ?? []

            if (images.length === 0) {
                images = this.rocketDetails(launch)?.flickr_images ?? []
            }

            return images[0] ?? null
        },
        // eslint-disable-next-line camelcase
        launchDateTime ({ date_unix }) {
            const dt = DateTime.fromSeconds(date_unix)
            return dt.toLocaleString(DateTime.DATETIME_SHORT)
        },
        rocketName (launch) {
            return this.rocketDetails(launch)?.name ?? 'Unknown'
        },
        rocketDetails ({ rocket }) {
            return this.$store.getters['spacex/rocket'](rocket)
        },
        rocketSuccessRate (launch) {
            const successRate = this.rocketDetails(launch)?.success_rate_pct ?? null
            return successRate ? `${successRate}%` : ''
        },
        viewLaunch ({ id }) {
            this.$router.push({
                name: 'spacex-launch',
                params: {
                    launchId: id
                }
            })
        },
        launchesByPad ({ id }) {
            return this.upcomingLaunches.filter(launch => launch.launchpad === id)
        }
    }
}
</script>
